/* src/styles/header.css */

/* Glow Text Effect */
.glow-text {
    color: #ffffff; /* Set text color to black or any preferred color */
  
    /* Apply white glow using text-shadow */
    text-shadow:
      0 0 3px rgba(255, 255, 255, 0.5),
      0 0 5px rgba(144, 144, 144, 0.454);
  }
  