@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Import Custom Fonts */
@import './fonts.css';
@import './header.css';
@import './glitch.css';

/* Add this to your global CSS file (e.g., tailwind.css) or AsciiArt.css */
.no-scroll {
    overflow: hidden;
    height: 100vh;
    primary: '#1a202c';
    secondary: '#2d3748';
    background: '#f7fafc';
    text: '#2d3748';
  }
  /* src/index.css */

  /* for team section */
  .team-card {
    perspective: 800px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform;
  }
  
  
  .core-service-card {
    /* Any additional styles */
  }  
  
/* src/styles/home.css */

.about-link {
  display: inline-flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.about-link:hover {
  transform: scale(1.1);
}
