/* src/styles/fonts.css */

/* Header Font: Koulen */
@font-face {
    font-family: 'Koulen';
    src: url('../assets/fonts/koulen/koulen_font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  /* Subheader Font: Bebas */
  @font-face {
    font-family: 'Bebas';
    src: url('../assets/fonts/bebas/bebas_font.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  /* Regular Font: Barlow */
  @font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/barlow/barlow_font.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  