/* src/styles/glitch.css */

.glitch-text {
    position: relative;
    color: #f53939ed; /* Adjust color as needed */
    line-height: 1;
    text-align: center;
    overflow: hidden;
  }
  
  .glitch-text::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #000;
    animation: glitch-shift 4s infinite linear;
    clip-path: polygon(0 2%, 100% 2%, 100% 8%, 0% 8%);
  }
  
  @keyframes glitch-shift {
    0% {
      transform: translate(0);
      clip-path: polygon(0 2%, 100% 2%, 100% 8%, 0% 8%);
    }
    10% {
      transform: translate(-2px, -2px);
      clip-path: polygon(0 15%, 100% 15%, 100% 20%, 0% 20%);
    }
    20% {
      transform: translate(2px, 2px);
      clip-path: polygon(0 30%, 100% 30%, 100% 35%, 0% 35%);
    }
    30% {
      transform: translate(-2px, -2px);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0% 50%);
    }
    40% {
      transform: translate(2px, 2px);
      clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0% 65%);
    }
    50% {
      transform: translate(-2px, -2px);
      clip-path: polygon(0 75%, 100% 75%, 100% 80%, 0% 80%);
    }
    60% {
      transform: translate(2px, 2px);
      clip-path: polygon(0 85%, 100% 85%, 100% 90%, 0% 90%);
    }
    70% {
      transform: translate(-2px, -2px);
      clip-path: polygon(0 95%, 100% 95%, 100% 100%, 0% 100%);
    }
    80% {
      transform: translate(2px, 2px);
      clip-path: polygon(0 0%, 100% 0%, 100% 5%, 0% 5%);
    }
    90% {
      transform: translate(-2px, -2px);
      clip-path: polygon(0 10%, 100% 10%, 100% 15%, 0% 15%);
    }
    100% {
      transform: translate(0);
      clip-path: polygon(0 2%, 100% 2%, 100% 8%, 0% 8%);
    }

  }

  .outer-glow-image {
    filter: drop-shadow(0 0 15px rgba(183, 31, 17, 0.8));
    animation: glowPulse 3s infinite;
  }
  
  @keyframes glowPulse {
    0% {
      filter: drop-shadow(0 0 15px rgba(211, 206, 202, 0.3));
    }
    50% {
      filter: drop-shadow(0 0 25px rgba(222, 221, 221, 0.7));
    }
    100% {
      filter: drop-shadow(0 0 15px rgba(193, 190, 190, 0.3));
    }
  }
