/* src/styles/ScrollImageSequence.css */

/* Container for the scroll sequence */
.scroll-sequence-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-color: black; /* Black background for contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflowing content */
  perspective: 1000px; /* Add perspective for 3D transformations */
}

/* Canvas for frame sequence */
.scroll-sequence-canvas {
  width: 80%;
  max-width: 1200px; /* Maximum width for larger screens */
  height: auto;
  /* border: 2px solid #fff; Optional: White border for visibility */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Optional: Subtle shadow */
  display: block;
}

/* Loading indicator */
.loading-indicator {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
}

/* Flying Images Styles */
.flying-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0); /* Start at center with 0 scale */
  width: 200px; /* Increased default size for better visibility */
  height: auto;
  opacity: 1;
  pointer-events: none; /* Prevent interaction */
  z-index: 2; /* Ensure flying images appear above the canvas */
  backface-visibility: visible; /* Ensure backside is visible during rotation */
  transform-style: preserve-3d; /* Enable 3D transformations */
}

/* Specific sizes for each flying image for visual diversity */
.flying-image-0 {
  width: 180px;
}

.flying-image-1 {
  width: 200px;
}

.flying-image-2 {
  width: 220px;
}

.flying-image-3 {
  width: 240px;
}

.flying-image-4 {
  width: 190px;
}

.flying-image-5 {
  width: 210px;
}

.flying-image-6 {
  width: 230px;
}

.flying-image-7 {
  width: 250px;
}

.flying-image-8 {
  width: 185px;
}

.flying-image-9 {
  width: 205px;
}

.flying-image-10 {
  width: 225px;
}

.flying-image-11 {
  width: 245px;
}

.flying-image-12 {
  width: 195px;
}

.flying-image-13 {
  width: 215px;
}

.flying-image-14 {
  width: 235px;
}

.flying-image-15 {
  width: 255px;
}

/* Optional: Add subtle shadows or styles */
.flying-image-0,
.flying-image-2,
.flying-image-4,
.flying-image-6,
.flying-image-8,
.flying-image-10,
.flying-image-12,
.flying-image-14 {
  filter: drop-shadow(0 0 50px rgb(165, 165, 165)); /*  shadow */
}

.flying-image-1,
.flying-image-3,
.flying-image-5,
.flying-image-7,
.flying-image-9,
.flying-image-11,
.flying-image-13,
.flying-image-15 {
  filter: drop-shadow(0 0 50px rgb(238, 237, 222)); /*  shadow */
}
